<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">NOTIFICATION SETTINGS</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none': 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <table class="w-100">
              <thead class="header">
                <tr>
                  <th class="px-3">Notification Name</th>
                  <th class="text-center">Time</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr class="lh-40" v-for="(item,index) in form" :key="index">
                  <td class="px-3 py-1">{{item.text}}</td>
                  <td class="text-center px-3 lh-40-mobile">
                    <datetime
                      type="time"
                      format="HH:mm"
                      v-model="item.time"
                      class="text-center time"
                    ></datetime>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Button Cancel  Exit -->
            <b-row class="mt-5">
              <b-col md="6"></b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >Save</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";

export default {
  name: "NotificationSettings",
  components: {
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      showPreview: "",
      isDisable: false,
      isBusy: false,
      items: [],
      form: null,
      modalMessage: "",
    };
  },
  mounted: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    saveForm: async function (flag) {
      this.isDisable = true;

      for (const data of this.form) {
        if (this.$moment(data.time, "HH:mm:ss").format("HH:mm:ss") != data.time)
          data.time = this.$moment(data.time).format("HH:mm:ss");
      }

      let data = {
        notification: this.form,
      };

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/notification/setting`,
        null,
        this.$headers,
        data
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    getData: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/notification/setting`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.form = data.detail;
      }
    },
  },
};
</script>

<style scoped>
.header {
  background: #f47306;
  color: white;
  line-height: 40px;
  font-size: 16px;
}

tbody {
  background: #dcdcdc;
}

.lh-40 {
  line-height: 40px;
}

@media (max-width: 600px) {
  .lh-40 {
    line-height: normal;
    padding-bottom: 10px;
  }

  .lh-40-mobile {
    line-height: 40px;
  }
}
</style>